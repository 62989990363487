// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated, waitForKeycloak } from '@/plugins/keycloak'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/library',
      name: 'library',
      component: () => import('../views/LibraryView.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/library/:id',
      name: 'recording-detail',
      component: () => import('../views/RecordingWrapper.vue'),
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/new',
      name: 'recording-setup',
      component: () => import('../views/RecordingSetupView.vue'),
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import('../views/SignInView.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/RegisterView.vue')
    },
    // {
    //   path: '/account',
    //   name: 'account',
    //   component: () => import('../views/AccountView.vue'),
    //   meta: { requiresAuth: true }
    // },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('../views/ForgotPassword.vue')
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  try {
    // Skip authentication check for public routes
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      return next()
    }

    // Wait for Keycloak to be fully initialized
    await waitForKeycloak()
    const authenticated = isAuthenticated()

    if (!authenticated) {
      // Save the attempted URL for redirect after login
      return next({
        name: 'signin',
        query: { redirect: to.fullPath }
      })
    }

    // If authenticated and trying to access auth pages, redirect to library
    if ((to.name === 'signin' || to.name === 'register') && authenticated) {
      return next({ name: 'library' })
    }

    // Proceed with navigation
    return next()
  } catch (error) {
    console.error('Navigation guard error:', error)
    // Only redirect to signin if not already authenticated
    if (!isAuthenticated()) {
      next({ name: 'signin' })
    } else {
      next()
    }
  }
})

// Add global error handler for navigation failures
router.onError((error) => {
  console.error('Router error:', error)
  // Handle navigation errors appropriately
})

export default router
