// src/i18n/index.js

import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    env: {
      date_fmt: 'en-US'
    },
    home: {
      main_h1: 'Record Your Conversations Privately And Get Intelligent Insights',
      main_h2:
        'Unlock the Power of Your Dialogues with AI-Driven Insights with Uncompromising Quality, Privacy and Security',
      main_forgov: 'FOR GOVERMENT',
      main_forhealth: 'FOR HEALTHCARE',
      main_getstarted: 'Get Started'
    },
    library: {
      title: 'Library',
      search_title: 'Search',
      search_placeholder: 'Search sessions...',
      new_session: 'New Session',
      results_nosessionsfound: 'No sessions found. Try a different search or create a new session.',
      results_btn_createnewsession: 'Create New Session'
    },
    newSession: {
      session_name: 'Session Name',
      session_name_error: 'Please enter a session name.',
      number_of_participants: 'Number of Participants',
      AI_processing_options: 'AI Processing Options',
      recommended_label: 'Recommended',
      continue_btn: 'Continue',
      language: 'Language',
      language_german: 'German',
      language_english: 'English',
      language_french: 'French',
      language_italian: 'Italian',
      default_session_name: 'New Session'
    },
    sessionRecording: {
      session_title: 'New Session',
      session_details_title: 'Your session',
      session_recording_title: 'Get started',
      session_recording_btn_or: 'or',
      session_recording_btn_recordaudio: 'Record Audio',
      session_recording_btn_uploadaudiofile: 'Upload Audio File',
      session_delete_btn_text: 'Delete',
      session_recording_complete: 'Recording Complete',
      tabs_title_session: 'Session',
      tabs_title_notes: 'Notes',
      recording_control_recording: 'Recording',
      recording_control_paused: 'Paused',
      uploading_text: 'Uploading...',
      tryagain_text: 'Versuche es nochmals...',
      finishsession_text: 'Finish Session',
      gotosession_text: ' Go to Session Page'
    },
    sessionDetail: {
      details_title: 'Details',
      details_name: 'Name:',
      details_duration: 'Duration:',
      details_participants: 'Participants:',
      details_language: 'Language:',
      details_date: 'Date:',
      status_title: 'Status',
      actions_title: 'Actions',
      actions_share: 'Share',
      actions_process: 'Process',
      actions_delete: 'Delete',
      notes_title: 'Notes',
      notes_placeholder: 'Enter your notes here...',
      actions_modal_delete_title: 'Delete Session',
      actions_modal_delete_subtitle: 'Are you sure you want to delete this Session?',
      actions_modal_delete_btn_cancel: 'Cancel',
      actions_modal_delete_btn_delete: 'Delete',
      actions_modal_share_title: 'Share',
      actions_modal_share_btn_email: 'Share via Email',
      actions_modal_share_btn_pdf: 'Share via PDF',
      actions_modal_share_btn_cancel: 'Cancel',
      actions_modal_edit_title: 'Edit Session Name',
      actions_modal_edit_placeholder: 'Enter session name',
      actions_modal_edit_btn_cancel: 'Cancel',
      actions_modal_edit_btn_save: 'Save',
      actions_modal_edit_btn_saving: 'Saving...',
      actions_modal_edit_btn_process: 'Process',
      actions_modal_process_title: 'Process Recording',
      tab_isprocessing_title: 'is Processing',
      tab_isprocessing_text: 'The content is not yet available. Please check back later.'
    },
    transcript: {
      speakers_info: 'Speakers',
      transcript_header: 'Transcript',
      speaker_label: 'Speaker',
      renamespeaker: 'Rename Speaker'
    },
    signin: {
      title: 'Sign in to your account',
      btn_signinwithkeycloak: 'Sign in',
      btn_singingIn: 'Signing in...'
    },
    tabs: {
      session: 'Session',
      transcript: 'Transcript',
      notes: 'Notes',
      facts: 'Facts',
      summary: 'Summary',
      topics: 'Topics',
      sentiment: 'Sentiment',
      whowhatwhenwherewhy: 'Who What When Where Why',
      erstkonsultation: 'Initial consultation',
      therapiesitzung: 'Therapy session'
    },
    pwa: {
      install_title: 'Install Alpine Listener',
      install_subtitle: 'Install the app for a better experience',
      update_title: 'Update Available',
      update_subtitle: 'Refresh to get the latest version',
      install_button: 'Install',
      update_button: 'Update',
      dismiss: 'Dismiss'
    }
  },
  de: {
    env: {
      date_fmt: 'de-DE'
    },
    home: {
      main_h1: 'Zeichnen Sie Ihre Gespräche privat auf und erhalten Sie intelligente Erkenntnisse',
      main_h2:
        'Erschließen Sie das Potenzial Ihrer Dialoge mit KI-gesteuerten Erkenntnissen bei kompromissloser Qualität, Privatsphäre und Sicherheit',
      main_forgov: 'FÜR BEHÖRDEN',
      main_forhealth: 'FÜR HEALTHCARE',
      main_getstarted: 'Jetzt Starten'
    },
    library: {
      title: 'Bibliothek',
      search_title: 'Suche',
      search_placeholder: 'Such in den Sessions...',
      new_session: 'Neue Session',
      results_nosessionsfound:
        'Es wurden keine Session gefunden. Versuchen Sie eine andere Suche oder erstellen Sie eine neue Session.',
      results_btn_createnewsession: 'Neue Session erstellen'
    },
    newSession: {
      session_name: 'Session Name',
      session_name_error: 'Bitte geben Sie einen Namen für die Session ein.',
      number_of_participants: 'Anzahl der Teilnehmer',
      AI_processing_options: 'KI-Verarbeitungsoptionen',
      recommended_label: 'Empfohlen',
      continue_btn: 'Weiter',
      language: 'Sprache',
      language_german: 'Deutsch',
      language_english: 'Englisch',
      language_french: 'Französisch',
      language_italian: 'Italienisch',
      default_session_name: 'Neue Session'
    },
    sessionRecording: {
      session_title: 'Neue Session',
      session_details_title: 'Ihre Session',
      session_recording_title: "Los geht's",
      session_recording_btn_or: 'oder',
      session_recording_btn_recordaudio: 'Audio Aufnehmen',
      session_recording_btn_uploadaudiofile: 'Audio Datei Hochladen',
      session_delete_btn_text: 'Löschen',
      session_recording_complete: 'Aufnahme ist fertig',
      tabs_title_session: 'Session',
      tabs_title_notes: 'Notizen',
      recording_control_recording: 'Wird aufgenommen',
      recording_control_paused: 'Pausiert',
      uploading_text: 'Wird hochgeladen...',
      tryagain_text: 'Versuche es nochmals...',
      finishsession_text: 'Session beenden',
      gotosession_text: ' Zur Session Seite'
    },
    sessionDetail: {
      details_title: 'Details',
      details_name: 'Name:',
      details_duration: 'Dauer:',
      details_participants: 'Teilnehmer:',
      details_language: 'Sprache:',
      details_date: 'Datum:',
      status_title: 'Status',
      actions_title: 'Aktionen',
      actions_share: 'Teilen',
      actions_process: 'Verarbeiten',
      actions_delete: 'Löschen',
      notes_title: 'Notizen',
      notes_placeholder: 'Geben Sie hier Ihre Notizen ein ...',
      actions_modal_delete_title: 'Session löschen',
      actions_modal_delete_subtitle: 'Möchten Sie diese Session wirklich löschen?',
      actions_modal_delete_btn_cancel: 'Abbrechen',
      actions_modal_delete_btn_delete: 'Löschen',
      actions_modal_share_title: 'Teilen',
      actions_modal_share_btn_email: 'Teilen via Email',
      actions_modal_share_btn_pdf: 'Teilen via PDF',
      actions_modal_share_btn_cancel: 'Abbrechen',
      actions_modal_edit_title: 'Session-Name bearbeiten',
      actions_modal_edit_placeholder: 'Ihr Session-Name',
      actions_modal_edit_btn_cancel: 'Abbrechen',
      actions_modal_edit_btn_save: 'Speichern',
      actions_modal_edit_btn_saving: 'Am Speichern...',
      actions_modal_edit_btn_process: 'Verarbeiten',
      actions_modal_process_title: 'Aufzeichnung Verabreiten',
      tab_isprocessing_title: 'wird Verabreitet',
      tab_isprocessing_text:
        'Der Inhalt ist noch nicht verfügbar. Bitte schauen Sie später noch einmal vorbei.'
    },
    transcript: {
      speakers_info: 'Sprecher',
      transcript_header: 'Transkript',
      speaker_label: 'Sprecher/in',
      renamespeaker: 'Sprecher/in Umbenennen'
    },
    signin: {
      title: 'Melden Sie sich in Ihrem Konto an',
      btn_signinwithkeycloak: 'Anmelden',
      btn_singingIn: 'Am Anmelden...'
    },
    tabs: {
      session: 'Session',
      transcript: 'Transkript',
      notes: 'Notizen',
      facts: 'Fakten',
      summary: 'Zusammenfassung',
      topics: 'Themen',
      sentiment: 'Stimmung',
      whowhatwhenwherewhy: 'Wer Was Wann Wo Warum',
      erstkonsultation: 'Erstkonsultation',
      therapiesitzung: 'Therapiesitzung'
    },
    pwa: {
      install_title: 'Alpine Listener installieren',
      install_subtitle: 'Installiere die App für ein besseres Erlebnis',
      update_title: 'Update verfügbar',
      update_subtitle: 'Aktualisiere, um die neueste Version zu erhalten',
      install_button: 'Installieren',
      update_button: 'Aktualisieren',
      dismiss: 'Schliessen'
    }
  }
}

export const i18n = createI18n({
  locale: localStorage.getItem('preferredLanguage') || 'de', // Get saved language or use default
  fallbackLocale: 'de',
  messages
})

export const t = (key) => i18n.global.t(key)
