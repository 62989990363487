import './assets/main.css'
import './assets/base.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { initKeycloak, getToken } from './plugins/keycloak'
import { i18n } from './i18n'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.use(i18n)

// Configure axios
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:1234'

// Setup axios interceptors
axios.interceptors.request.use(async (config) => {
  const token = await getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// Handle 401 responses
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const { useUserStore } = await import('./stores/userStore')
      const userStore = useUserStore()
      await userStore.logout()
      router.push('/signin')
    }
    return Promise.reject(error)
  }
)

// Improved Service Worker Registration
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      // Check for existing registrations
      const existingRegistration = await navigator.serviceWorker.getRegistration()
      if (existingRegistration) {
        // If there's an update waiting, activate it
        if (existingRegistration.waiting) {
          existingRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
      }

      const registration = await navigator.serviceWorker.register('/service-worker.js', {
        scope: '/',
        updateViaCache: 'none'
      })

      // Handle updates
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing
        newWorker?.addEventListener('statechange', () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            // New content is available, notify user if needed
            console.log('New content is available; please refresh.')
          }
        })
      })

      // Handle controller change
      // let refreshing = false
      // navigator.serviceWorker.addEventListener('controllerchange', () => {
      //   if (!refreshing) {
      //     refreshing = true
      //     window.location.reload()
      //   }
      // })

      console.log('Service Worker registered successfully')
    } catch (error) {
      console.error('Service Worker registration failed:', error)
    }
  }
}

// Initialize the application
const initApp = async () => {
  try {
    await initKeycloak(async () => {
      const { useUserStore } = await import('./stores/userStore')
      const userStore = useUserStore()
      try {
        await userStore.fetchUserInfo()
      } catch (error) {
        console.error('Failed to fetch user info:', error)
      }
      app.mount('#app')

      // Register service worker after app is mounted
      await registerServiceWorker()
    })
  } catch (error) {
    console.error('Failed to initialize app:', error)
    app.mount('#app')
  }
}

// Start the app initialization
initApp().catch((error) => {
  console.error('App initialization failed:', error)
  app.mount('#app')
})
